.container-one {
  display: flex;
  flex-direction: column;
  padding-top: 94px;
  z-index: 99;
}

.container-one .zim-select{
  width: 100vw;
  height: 50px;
  margin-top: 2px;
}

.container-system {
  background: #F7F7F7;
  /* padding-top: 94px; */
}
.container-system .lnb {
  position: fixed;
  top: 56px;
  left: 0;
  right: 0;
  background-color: #fff;
}
.container-system .lnb nav {
  width: 100vw;
}
.container-system .lnb nav ul {
  display: flex;
}
.container-system .lnb nav ul li {
  flex: 0 0 20%;
}
.container-system .lnb nav ul li.active a {
  background: #623189;
  color: #fff;
}
.container-system .lnb nav ul li a {
  display: block;
  width: 100%;
  height: 38px;
  line-height: 38px;
  text-align: center;
  font-size: 14px;
  background: #fff;
}
.container-system .lnb nav ul li a i {
  display: none;
}
.container-system .content {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-height: calc(100vh - 300px);
  padding: 20px 10px;
}
.container-system .content .title {
  margin: 0px;
  padding: 20px 10px;
  border-bottom: 1px solid #E5E5E5;
}
.container-system .content .sub-title {
  margin: 0;
  padding: 20px 10px;
  border-top: 1px solid #E5E5E5;
}

.container-system .content .title h1 {
  font-weight: 400;
  margin-bottom: 10px;
}
.container-system .content .sub-title h1 {
  font-weight: 400;
  margin-bottom: 10px;
}
@media (min-width: 1020px) {
  .container-system .content .title h1 {
    font-size: 24px;
  }
  .container-system .content .sub-title h1 {
    font-size: 24px;
  }
}
.container-system .content .title p {
  font-size: 14px;
  line-height: 1.4em;
}
.container-system .content .sub-title p {
  font-size: 14px;
  line-height: 1.4em;
}
@media (min-width: 1020px) {
  .container-system .content .title p {
    font-size: 16px;
  }
  .container-system .content .sub-title p {
    font-size: 16px;
  }
}

@media (min-width: 768px) and (max-width: 1019px) {
  .container-system {
    padding-top: 116px;
  }
  .container-system .lnb {
    top: 56px;
  }
  .container-system .lnb nav ul li a {
    height: 60px;
    line-height: 60px;
    font-size: 20px;
  }
}
@media (min-width: 1020px) {
  .container-one {
    padding-top: 98px;
  }
  .container-one .zim-select{
    margin-left: 50px;
    margin-top: 10px;
    width: 17vw;
  }
 
  .container-system {
    display: flex;
    padding-top: 0px;
  }
  .container-system .lnb {
    position: relative;
    top: unset;
    left: unset;
    right: unset;
    width: 300px;
    flex: 0 0 300px;
    padding: 55px 30px;
    border-right: 1px solid #D9D9D9;
    background: none;
  }
  .container-system .lnb nav ul {
    flex-direction: column;
  }
  .container-system .lnb nav ul li a {
    background: none;
    padding: 0 30px;
    height: 67px;
    line-height: 67px;
    font-size: 18px;
    border-radius: 10px;
    text-align: left;
  }
  .container-system .lnb nav ul li a i {
    display: inline-flex;
    width: 40px;
    height: 38px;
    vertical-align: middle;
    margin-right: 8px;
    align-items: center;
    justify-content: center;
  }
  .container-system .lnb nav ul li.active i img {
    filter: invert(100%);
  }
  .container-system .content {
    min-height: calc(100vh - 485px);
    /* padding: 55px 50px; */
  }
  .container-system .content .title {
    padding-top: 0;
  }
  .container-system .content .sub-title {
    margin-top: 50px;
    padding-top: 50px;
  }
}
@media (min-width: 1920px) {
  .container-system .lnb {
    width: 426px;
    flex: 0 0 426px;
    padding: 55px 50px;
  }
}

.container-system .content .title {
  display: flex;
  justify-content: space-between;
  padding: 10px 50px;
}

.container-system .content .title .report{
  display: block;
  align-items: end;
  align-content: end;
}

.container-system .content .title .report img{
  width: 48px;
}

/* .header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: #fff;
  z-index: 998;
}
@media (min-width: 1020px) {
  .header {
    height: 98px;
    border-bottom: 1px solid #D9D9D9;
  }
}
.header .inner {
  display: flex;
  align-items: center;
  height: inherit;
}
@media (min-width: 1020px) {
  .header .inner {
    padding: 0 clamp(10px, 1vw, 20px);
    max-width: unset;
    width: auto;
  }
}
@media (min-width: 1920px) {
  .header .inner {
    padding: 0 50px;
  }
}
.header .inner .header-logo {
  flex: 0 1 auto;
  width: 130px;
  height: 22px;
  background: url("../images/common/logo.svg") center no-repeat;
  background-size: 100%;
}
@media (min-width: 1020px) {
  .header .inner .header-logo {
    min-width: 88px;
    width: 263px;
    height: 45px;
    margin-left: 0;
    transform: translateY(-4px);
  }
}
.header .inner .header-logo span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.header .inner .btn-header {
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  background: url("../images/common/btn_gnb.svg") center no-repeat;
  background-size: 35%;
  transform: translateX(-10px);
}
@media (min-width: 1020px) {
  .header .inner .btn-header {
    background-size: 70%;
  }
}
@media (min-width: 1020px) {
  .header .inner .btn-header {
    display: none;
  }
}
.header .inner .btn-header span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.header .inner .gnb {
  display: none;
  height: inherit;
}
@media (min-width: 1020px) {
  .header .inner .gnb {
    display: flex;
    flex: 1 1 auto;
  }
}
.header .inner .gnb.active {
  display: block;
}
.header .inner .gnb .top-nav {
  height: inherit;
  flex: 1 1 auto;
}
.header .inner .gnb .top-nav .navi-list {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.header .inner .gnb .top-nav .navi-list a {
  padding: 8px;
}
@media (min-width: 1020px) {
  .header .inner .gnb .top-nav .navi-list a {
    font-size: clamp(13px, 4vw, 19px);
    padding: 8px 1.5vw;
  }
}
@media (min-width: 1920px) {
  .header .inner .gnb .top-nav .navi-list a {
    font-size: 21px;
    padding: 8px 25px;
  }
}
.header .inner .gnb .top-nav .navi-list a span {
  white-space: nowrap;
  font-weight: 700;
  color: #222;
}
.header .inner .gnb .utility-nav {
  flex: 0 1 auto;
  height: inherit;
}
.header .inner .gnb .utility-nav ul {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.header .inner .gnb .utility-nav ul li:last-child {
  margin: 0;
}
@media (min-width: 1020px) {
  .header .inner .gnb .utility-nav ul li {
    margin: 0 14px;
  }
}
.header .inner .gnb .utility-nav ul li a span {
  white-space: nowrap;
  color: #777;
}
.header .inner .gnb .utility-nav ul li button span {
  white-space: nowrap;
}
.header .inner .avatar {
  display: flex;
  align-items: center;
  margin-left: auto;
  flex-direction: row-reverse;
}
.header .inner .avatar .avatar-img {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  overflow: hidden;
}
.header .inner .avatar .avatar-img img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.header .inner .avatar .avatar-text {
  display: none;
  text-align: right;
  margin-right: 12px;
}
@media (min-width: 1020px) {
  .header .inner .avatar .avatar-text {
    display: block;
  }
}
.header .inner .avatar .avatar-text .position {
  display: block;
  margin-bottom: 4px;
  color: #999999;
  font-size: 14px;
}
.header .inner .avatar .avatar-text .name strong {
  position: relative;
  display: inline-block;
  padding-right: 8px;
  margin-right: 8px;
  font-weight: 800;
}
.header .inner .avatar .avatar-text .name strong::after {
  content: "";
  display: block;
  position: absolute;
  width: 1px;
  height: 13px;
  background: #D9D9D9;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.header .inner .avatar .avatar-text .name span {
  font-weight: 800;
}

.mobile-header {
  display: none;
}
.mobile-header.active {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  overflow: hidden auto;
}
@media (min-width: 1020px) {
  .mobile-header.active {
    display: none;
  }
}
.mobile-header .mobile-header-inner {
  display: flex;
  min-height: 100%;
  width: 100%;
  padding: 0 20px 20px;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-header .mobile-header-inner .mobile-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
.mobile-header .mobile-header-inner .mobile-header-top .header-logo {
  flex: 0 1 auto;
  width: 88px;
  height: 22px;
  background: url("../images/common/logo.svg") center no-repeat;
  background-size: 100%;
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .header-logo {
    min-width: 88px;
    width: 176px;
    height: 44px;
    margin-left: 0;
    transform: translateY(-4px);
  }
}
.mobile-header .mobile-header-inner .mobile-header-top .header-logo span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.mobile-header .mobile-header-inner .mobile-header-top .btn-close {
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  background: url("../images/common/btn_close.svg") center no-repeat;
  background-size: 35%;
  transform: translateX(10px);
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .btn-close {
    background-size: 70%;
  }
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .btn-close {
    display: none;
  }
}
.mobile-header .mobile-header-inner .mobile-header-top .btn-close span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.mobile-header .mobile-header-inner .mobile-gnb {
  flex: 1 1 auto;
  padding: 20px;
  font-size: 14px;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list {
  margin-bottom: 100px;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub .depth1 {
  background: url("../images/common/btn_mobile_close.svg") right center no-repeat;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub .navi-sub-list {
  display: none;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub.active .depth1 {
  background: url("../images/common/btn_mobile_more.svg") right center no-repeat;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub.active .navi-sub-list {
  display: block;
  padding: 20px 0;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .btn-navi {
  display: block;
  width: 100%;
  text-align: left;
  padding: 20px 0;
  color: #000;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .btn-navi.depth2 {
  padding: 10px 0;
  color: #777;
}
.mobile-header .mobile-header-inner .mobile-gnb .mobile-utility .button-primary {
  display: block;
  width: 100%;
  background-color: #222;
  line-height: 44px;
}
.mobile-header .mobile-header-inner .mobile-navi-copy {
  font-size: 12px;
  color: 999;
  text-align: center;
}

.footer {
  padding: 84px 16px 68px;
  background: #222;
  color: #C1C1C1;
  font-size: 16px;
}
@media (min-width: 1020px) {
  .footer {
    font-size: 19px;
  }
}
.footer .inner {
  width: 100%;
  max-width: unset;
}
@media (min-width: 1020px) {
  .footer .inner {
    max-width: 1920px;
  }
}
.footer .inner h2 {
  color: #fff;
  font-size: 0.813em;
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  .footer .inner h2 {
    margin-bottom: 12px;
  }
}
.footer .inner .footer-logo {
  margin-bottom: 40px;
  font-family: "inter";
  font-weight: 400;
  font-size: 24px;
  color: #fff;
}
.footer .inner .footer-data {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data {
    flex-direction: row;
  }
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data {
    max-width: 612px;
  }
}
.footer .inner .footer-data .company-data h2 {
  display: none;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data h2 {
    display: block;
  }
}
.footer .inner .footer-data .company-data p {
  word-break: keep-all;
  font-size: 0.75em;
  line-height: 1.667em;
}
.footer .inner .footer-data .company-data p.copy {
  display: none;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data p.copy {
    display: block;
    padding-top: 1.5em;
  }
}
.footer .inner .footer-data .footer-guide {
  padding: 42px 0;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-guide {
    flex: 0 0 300px;
    padding: 0;
    margin-right: clamp(40px, 14%, 150px);
    margin-left: clamp(40px, 14%, 285px);
  }
}
.footer .inner .footer-data .footer-guide h2 {
  margin-bottom: 4px;
}
.footer .inner .footer-data .footer-guide ul li {
  border-bottom: 1px solid #999999;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-guide ul li {
    background: url("../images/common/footer_arrow.png") right center no-repeat;
  }
}
.footer .inner .footer-data .footer-guide ul li a {
  display: block;
  padding: 16px 0;
  font-size: 0.75em;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-contact {
    flex: 0 0 180px;
  }
}
.footer .inner .footer-data .footer-contact ul li {
  margin-bottom: 8px;
  font-size: 0.75em;
}
.footer .inner .footer-data .bottom-copy {
  font-size: 0.75em;
  padding-top: 40px;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .bottom-copy {
    display: none;
  }
} */

.input-check-zim input {
  display: none;
}
.input-check-zim input:checked + label::before {
  background: url("../images/common/checked.png") center no-repeat #623189;
  background-size: 50%;
}
.input-check-zim label {
  position: relative;
  display: block;
  cursor: pointer;
  font-size: 13px;
  padding: 0 5px 0 18px;
  line-height: 22px;
}
.input-check-zim label:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 4px;
  width: 10px;
  height: 10px;
  border: 1px solid #E5E5E5;
  margin-right: 8px;
}

@media (min-width: 1020px) {
  .input-check-zim label {
    font-size: 16px;
    padding: 0 5px 5px 30px;
  }
  .input-check-zim label:before {
    top: 0;
    width: 20px;
    height: 20px;
  }
}
.textarea {
  display: block;
  width: 100%;
  height: 10em;
  padding: 5px;
  border: 1px solid #E5E5E5;
}
@media (min-width: 1020px) {
  .textarea {
    padding: 20px;
  }
}
.textarea.no-border {
  border: none;
}

.input-button {
  position: relative;
  display: block;
}
.input-button button {
  position: absolute;
  display: block;
  cursor: pointer;
  width: 40px;
  height: 40px;
  right: 0;
  top: 0;
  bottom: 0;
  font-size: 0;
}
.input-button button.icon-search {
  background: url("../images/common/btn_search.png") center no-repeat;
}
.input-button button.icon-plus {
  background: url("../images/common/btn_plus.png") center no-repeat;
}

.chart-bar {
  position: relative;
  margin: 10px 0 0 0;
}
.chart-bar .current {
  position: absolute;
  bottom: 25px;
  transform: translateX(-50%);
  z-index: 3;
  text-align: center;
  font-weight: 800;
}
.chart-bar .current::after {
  content: "";
  display: block;
  width: 32px;
  height: 32px;
  margin-top: 8px;
  background: #FFFFFF;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.chart-bar .list {
  position: relative;
  z-index: 0;
  display: flex;
  padding: 0 0 30px 20px;
}
.chart-bar .list li {
  position: relative;
  flex: 0 0 auto;
  width: calc(16.66% + 20px);
  height: 20px;
  border-radius: 10px;
  margin-left: -20px;
  background-color: #ccc;
}
.chart-bar .list li span {
  position: absolute;
  right: 5px;
  bottom: -24px;
}
.chart-bar .list li:first-child:before {
  content: "0";
  display: block;
  position: absolute;
  left: 0;
  bottom: -24px;
}
.chart-bar .list li:nth-child(1) {
  background-color: #FE8954;
}
.chart-bar .list li:nth-child(2) {
  background-color: #E64F67;
}
.chart-bar .list li:nth-child(3) {
  background-color: #B54F9B;
}
.chart-bar .list li:nth-child(4) {
  background-color: #6B5BB4;
}
.chart-bar .list li:nth-child(5) {
  background-color: #0D73C8;
}
.chart-bar .list li:nth-child(6) {
  background-color: #00A6DA;
}

.webpart-area {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: -10px;
  padding: 20px 0;
  width: 100%;
}
.webpart-area .webpart-item {
  flex: 1 0 auto;
  width: 100%;
  border-radius: 20px;
  margin: 10px;
  padding: 10px;
  background: #fff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1);
}
.webpart-area .webpart-item h2 {
  font-size: 22px;
}
.webpart-area .webpart-complex {
  display: flex;
  padding: 5px 0;
  flex-direction: column;
}
.webpart-area .webpart-complex .webpart-complex-item {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 15px;
  border-radius: 20px;
  margin: 5px 10px;
}
.webpart-area .webpart-complex .webpart-three-item {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 15px;
  border-radius: 20px;
  margin: 5px 10px;
}

@media (min-width: 1020px) {
  .webpart-area .webpart-complex {
    flex: 0 0 auto;
    width: calc(100% - 20px);
    flex-direction: row;
    padding: 0 10px;
  }
  .webpart-area .webpart-complex .webpart-complex-item {
    flex: 0 0 auto;
    width: calc(50% - 20px);
    padding: 30px;
    margin: 20px 10px;
  }
  .webpart-area .webpart-complex .webpart-three-item {
    flex: 0 0 auto;
    width: calc(33% - 16px);
    padding: 30px;
    margin: 20px 10px;
  }
  .webpart-area .webpart-item {
    width: calc(100% - 20px);
    padding: 20px;
  }
  .webpart-area .webpart-item.x2 {
    flex: 0 0 auto;
    width: calc(50% - 20px);
  }
  .webpart-area .webpart-item.y2 {
    min-height: 600px;
  }
}
.webpart-calendar h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_calender.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-visits h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_person_plus.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-complain h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_heart.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-check h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_person_plus.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-monthly-sevice h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_leaf.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-monthly-time h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_time.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-monthly-complete h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_time.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-checklist h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_calender_checked.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-visit-time h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_time.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-monthly-visit h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_calender_light.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-daily-checked h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_calender_checked_light.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-service-range-rate h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_handshake.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-service-list-rate h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_hands.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-complain-rate h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_handheart.png");
  margin-right: 10px;
  vertical-align: middle;
}

.webpart-clean-complete h2::before {
  content: "";
  display: inline-block;
  width: 50px;
  height: 50px;
  background-size: contain;
  background-image: url("../images/zim/icon_clean.png");
  margin-right: 10px;
  vertical-align: middle;
}
.dashboard-area{
  padding: 55px 50px;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box {
  display: flex;
  flex: 1 0 auto;
  justify-content: space-around;
  align-items: center;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li {
  position: relative;
  padding-left: 20px;
  margin: 15px 0;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li i {
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  left: 0;
  top: 5px;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li i.white {
  background: #fff;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li i.gray {
  background: #DCDEE4;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li i.purple {
  background: #623189;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li i.green {
  background: #B7EAD4;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li strong {
  display: block;
}
.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li span {
  display: block;
  font-size: 12px;
}
.dashboard-area .webpart-complex .webpart-visits {
  background: #F7F4F9;
}
.dashboard-area .webpart-complex .webpart-complain {
  background: #F4F9F7;
}
.dashboard-area .webpart-complex .webpart-check {
  background: #FFF3ED;
}


@media (min-width: 1920px) {
  .dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .text-area li span {
    font-size: 16px;
  }
  
  .dashboard-area .webpart-calendar {
    flex: 0 0 378px;
  }
  .dashboard-area .webpart-complex {
    /* flex: 0 0 calc(100% - 418px); */
  }
  .dashboard-area .webpart-monthly-statistics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
.checklist-area .webpart-checklist section {
  display: flex;
  padding: 25px 10px;
  border-bottom: 1px solid #D9D9D9;
  flex-wrap: wrap;
}
.checklist-area .webpart-checklist section:last-child {
  border-bottom: none;
}
.checklist-area .webpart-checklist section .checklist-title {
  width: 100%;
  flex: 0 0 100%;
  margin-bottom: 12px;
  font-size: 14px;
}
.checklist-area .webpart-checklist section .checklist-content {
  flex: 0 0 100%;
  color: #777;
  font-size: 13px;
}
.checklist-area .webpart-checklist section.range .checklist-content ul, .checklist-area .webpart-checklist section.list .checklist-content ul {
  display: flex;
  flex-wrap: wrap;
  margin: -2px 0;
}
.checklist-area .webpart-checklist section.range .checklist-content ul li, .checklist-area .webpart-checklist section.list .checklist-content ul li {
  flex: 0 0 100%;
  padding: 2px 0;
}
.checklist-area .webpart-checklist section.pic .checklist-content ul {
  display: flex;
  margin: 0 -7px;
}
.checklist-area .webpart-checklist section.pic .checklist-content ul li {
  flex: 0 0 calc(33.33% - 14px);
  margin: 0 7px;
  border-radius: 5px;
  overflow: hidden;
}
.checklist-area .webpart-checklist section.pic .checklist-content ul li img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}

@media (min-width: 1020px) {
  .checklist-area .webpart-calendar {
    /* flex: 0 0 378px; */
  }
  .checklist-area .webpart-checklist {
    flex: 1 0 calc(100% - 418px);
  }
  .checklist-area .webpart-checklist section .checklist-title {
    width: 150px;
    flex: 0 0 150px;
    margin-bottom: 0;
    font-size: 18px;
  }
  .checklist-area .webpart-checklist section .checklist-content {
    flex: 0 0 calc(100% - 150px);
    color: #777;
    font-size: 18px;
  }
  .checklist-area .webpart-checklist section.range .checklist-content ul, .checklist-area .webpart-checklist section.list .checklist-content ul {
    margin: -6px 0;
  }
  .checklist-area .webpart-checklist section.range .checklist-content ul li, .checklist-area .webpart-checklist section.list .checklist-content ul li {
    flex: 0 0 100%;
    padding: 6px 0;
  }
}
@media (min-width: 1920px) {
  .checklist-area .webpart-checklist section.range .checklist-content ul li, .checklist-area .webpart-checklist section.list .checklist-content ul li {
    flex: 0 0 50%;
  }
}
.report-date {
  display: flex;
  margin: 10px 0;
  padding: 10px 50px;
  justify-content: space-between;
}
.report-date .report-date-item {
  display: flex;
  flex: 0 0 auto;
  width: calc(50% - 20px);
  border-bottom: 1px solid #D9D9D9;
  font-size: 18px;
  padding: 10px 0;
}
.report-date .report-date-item label {
  font-weight: 800;
  width: 120px;
  flex: 0 0 120px;
  margin-top: 10px;
}
.report-date .report-date-item select {
  flex: 1 1 auto;
}
.report-date .report-date-item .select-area {
  flex: 1 1 auto;
}
.report-area{
  padding: 55px 50px;
}
.report-area .webpart-visit-time .monthly-visit-chart {
  margin: 20px 0 0 0;
}
.report-area .webpart-monthly-visit {
  background: #F7F4F9;
}
.report-area .webpart-daily-checked {
  background: #FFF3ED;
}

@media (min-width: 1020px) {
  .report-area .webpart-visit-time .monthly-visit-chart {
    margin: 60px 0 0 0;
  }
}
.form-area-zim .form-item-zim {
  display: flex;
  padding: 20px 10px;
  border-bottom: 1px solid #D9D9D9;
  flex-wrap: wrap;
}
.form-area-zim .form-item-zim:last-child {
  border-bottom: none;
}
.form-area-zim .form-item-zim .form-title {
  width: 100%;
  flex: 0 0 100%;
  font-weight: 800;
  margin-bottom: 12px;
  font-size: 14px;
}
.form-area-zim .form-item-zim .form-content {
  flex: 0 0 100%;
  color: #777;
  font-size: 13px;
}
.form-area-zim .form-item-zim .form-content input[type=text] {
  color: inherit;
  display: block;
  width: 100%;
}
.form-area-zim .form-item-zim.company .input-button button {
  top: -10px;
}
.form-area-zim .form-item-zim.range .form-content ul {
  margin: -2px 0;
}
.form-area-zim .form-item-zim.range .form-content ul li {
  padding: 2px 0;
}
.form-area-zim .form-complex {
  display: flex;
  flex-wrap: wrap;
  border-bottom: none;
  padding: 0;
}
.form-area-zim .form-complex .form-complex-item {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  padding: 20px 10px;
  border-bottom: 1px solid #D9D9D9;
}

@media (min-width: 1020px) {
  .form-area-zim .form-item-zim .form-title {
    width: 180px;
    flex: 0 0 180px;
    font-size: 18px;
    margin-bottom: 0;
  }
  .form-area-zim .form-item-zim .form-content {
    flex: 0 0 calc(100% - 180px);
    font-size: 18px;
  }
  .form-area-zim .form-item-zim.range .form-content ul {
    margin: -6px 0;
  }
  .form-area-zim .form-item-zim.range .form-content ul li {
    padding: 6px 0;
  }
  .form-area-zim .form-complex .form-complex-item {
    flex: 0 0 50%;
  }
}

.webpart-monthly-sevice .pie-area {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.webpart-monthly-sevice .pie-area .pie{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.webpart-monthly-sevice .pie-area .pie .pie-text-area{
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.webpart-monthly-sevice .pie-area .pie .pie-text-area .pie-title{
  text-align: center;
  margin-bottom: 4px;
}

.webpart-monthly-sevice .pie-area .pie .pie-text-area .pie-data{
  font-family: 'Lato';
  font-weight: 700;
}

.dashboard-area .webpart-complex .webpart-three-item .chart-explain-box .chart-area-service {
  background-size: 142px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../images/zim/dash_service.png");
}

.form-area-zim .check-item {
  display: flex;
  flex-wrap: wrap;
  border-bottom: none;
  padding: 0;
  justify-content: start;
}
.form-area-zim .form-item-zim.range .form-content ul li {
  margin-right: 15px;
  padding: 6px 0;
}

.webpart-area .webpart-item .webpart-select {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.webpart-area .webpart-item .select-area {
  width: 150px;
  font-size: 14px;
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 5px;
  z-index: 999;
}
.webpart-area .webpart-item .swiper .swiper-wrapper{
  padding: 0 20px;
}
.webpart-area .webpart-item .swiper .swiper-check {
  width: 60px !important;
  height: 82px;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  border-radius: 20px;
  margin-top: 5px;
  padding: 15px 0px;
  color: #000000;
}
.webpart-area .webpart-item .swiper .swiper-check:hover {
  background-color: #623189;
  color: #FFFFFF;
}
.webpart-area .webpart-item .swiper .swiper-check .week{
  font-family: "NanumSquareAcr";
  font-weight: 400;
  line-height: 20px;
}
.webpart-area .webpart-item .swiper .swiper-check .day{
  font-size: "Lato";
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
}

.swiper-button-next {
  width: 13px;
  height: 27px;
  margin-top: -15px;
  margin-left: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/zim/next.png");
}

.swiper-button-prev {
  width: 13px;
  height: 27px;
  margin-top: -15px;
  margin-right: 120px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("../images/zim/prev.png");
}

.swiper-button-next::after,
.swiper-button-prev::after {
  display: none;
}

@media (min-width: 1024px) {
  .webpart-area .webpart-item .select-area {
    width: 160px;
    font: 16px;
    margin-top: 5px;
    margin-left: 20px;
  }

  .webpart-area .webpart-item .webpart-select {
    justify-content: start;
  }

  .webpart-area .webpart-item .swiper .swiper-check {
    font-size: 18px;
  }
  

  .webpart-area .webpart-item .swiper .swiper-wrapper{
    padding: 0 50px;
  }
}

/* 230720 최종 수정 적용건 */
@media (min-width: 768px) and (max-width: 1019px){
  .container-one{padding-top:115px;}
}
.container-one .zim-select { padding:10px 20px; margin:0; height:auto; }

.webpart-area  [class ^= 'webpart-'] h2 { font-size:18px; padding-top: 5px;}
.webpart-area  [class ^= 'webpart-'] h2::before { width:30px; height:30px; }

@media (min-width: 1020px)  {
  .webpart-area  [class ^= 'webpart-'] h2 { font-size:22px; }
  .webpart-area  [class ^= 'webpart-'] h2::before { width:50px; height:50px; }
}
.header{ z-index:99999;}
.container-one  > div.zim-select  { z-index:1;}
.container-system .lnb { z-index:0;}

.report-date .report-date-item.r-date {  width: 100%; align-items: center; }
.report-date .report-date-item.r-date label { margin-top: 0; flex: 0 0 120px; }
.report-date .report-date-item.r-date .select-area { flex: 0 1 calc(100% - 120px); width:calc(100% - 120px); max-width: 300px; }
.report-area .webpart-visit-time .monthly-visit-chart { margin-top:64px }

.recharts-wrapper {
  font-size: 12px;
}

.recharts-responsive-container {
  margin-left: -10px;
}

@media (min-width: 1020px)  {
  .recharts-wrapper {
    font-size: 16px;
  }
}

.report {
  position: relative;
  text-align: center;
  break-after: page;
}

@media print {
  .page-break {
    page-break-before: always;
  }

  .report + .report {
    margin-top: 0;
  }
}

@page {
  size: A4;
  margin: 20mm;
}

@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
}

button, input, select, textarea {
  font-size: 100%;
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  background: none;
  border: none;
}

:focus {
  outline: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

table {
  display: table;
  border-collapse: collapse;
  border-spacing: 0;
}

ol, ul, li {
  list-style: none;
}

li {
  display: list-item;
}

tr {
  display: table-row;
}

thead {
  display: table-header-group;
}

tbody {
  display: table-row-group;
}

tfoot {
  display: table-footer-group;
}

col {
  display: table-column;
}

colgroup {
  display: table-column-group;
}

td,
th {
  display: table-cell;
}

caption {
  display: table-caption;
}

th {
  font-weight: bolder;
  text-align: center;
}

caption {
  text-align: center;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

a,
a:link,
a:focus,
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
  color: inherit;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
}

* {
  font-family: 'Noto Sans KR', sans-serif;
  box-sizing: border-box;
  transition: margin 0.5s, padding 0.5s;
}

body {
  font-size: clamp(16px, 4.28vw, 20px);
}
@media (min-width: 1020px) {
  body {
    font-size: 16px;
  }
}

:root {
  --swiper-theme-color: #222;
}

@keyframes textup {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@font-face {
  font-family: "one";
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/ONEMobile_Light.woff2) format("woff2"), url(../fonts/ONEMobile_Light.woff) format("woff");
}
@font-face {
  font-family: "one";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/ONEMobile_Regular.woff2) format("woff2"), url(../fonts/ONEMobile_Regular.woff) format("woff");
}
@font-face {
  font-family: "one";
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/ONEMobile_Bold.woff2) format("woff2"), url(../fonts/ONEMobile_Bold.woff) format("woff");
}
@font-face {
  font-family: "one";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/ONEMobile_Bold.woff2) format("woff2"), url(../fonts/ONEMobile_Bold.woff) format("woff");
}
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/InterRegular.woff2) format("woff2"), url(../fonts/InterRegular.woff) format("woff");
}
@font-face {
  font-family: "inter";
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/InterSemiBold.woff2) format("woff2"), url(../fonts/InterSemiBold.woff) format("woff");
}

.button-outline {
  padding: 10px 20px;
  font-size: 0.813rem;
  line-height: 2em;
  color: #fff;
  text-align: center;
  border: 1px solid #fff;
  cursor: pointer;
}
@media (min-width: 1020px) {
  .button-outline {
    font-size: 20px;
    padding: 13px 45px;
  }
}
.button-outline.gray {
  color: #777;
  border-color: #E5E5E5;
  margin-top: 30px;
}
.button-outline.black {
  color: #222;
  border-color: #222;
  margin-top: 30px;
}

.btn-right{
  width: 100%;
  text-align: right;
}
.button-primary {
  padding: 12px 8px;
  text-align: center;
  color: #fff;
  background: #623189;
  cursor: pointer;
}
.button-primary.border-radius {
  border-radius: 5px;
}
@media (min-width: 1020px) {
  .button-primary.size-l {
    padding: 0;
    height: 70px;
    line-height: 70px;
  }
  .button-primary.size-s {
    padding: 0 15px;
    height: 50px;
  }
}

.input-style {
  display: flex;
  flex-wrap: wrap;
}
.input-style label {
  display: none;
  font-size: 1em;
  line-height: 1em;
  padding: 21px 0;
}
.input-style .input-unit {
  display: block;
  width: 100%;
}
.input-style .input-unit input {
  width: 100%;
  font-size: 1em;
  line-height: 1em;
  padding: 20px;
  border: 1px solid #E5E5E5;
}
.input-style .input-unit input::-moz-placeholder {
  letter-spacing: 0;
  color: #999;
}
.input-style .input-unit input::placeholder {
  letter-spacing: 0;
  color: #999;
}
.input-style .input-unit .error-message{
  color: red;
  font-size: 12px;
  text-align: left;
  margin-left: 10px;
}
.input-style label {
  display: none;
}
.input-style .tip {
  width: 100%;
  text-align: left;
}

.input-style.type2 .input-unit {
  width: 100%;
}
.input-style.type2 .input-unit input {
  padding: 16px 20px;
}

.input-style.type2 .input-unit .error-message{
  color: red;
  font-size: 12px;
  text-align: center;
  margin-left: 10px;
}

@media (min-width: 1020px) {
  .input-style.type2 label {
    display: block;
    width: 115px;
    flex: 0 0 115px;
  }
  .input-style.type2 .input-unit {
    width: calc(100% - 115px);
  }
  .input-style.type2 .input-unit input {
    flex: 1 1 auto;
    width: 100%;
    padding: 20px;
  }
  .input-style.type2 .input-unit input:not([placehoder-acitve])::-moz-placeholder {
    color: transparent;
  }
  .input-style.type2 .input-unit input:not([placehoder-acitve])::placeholder {
    color: transparent;
  }
  .input-style.type2 .tip {
    padding-left: 115px;
  }
}

.radio-style input {
  display: none;
}
.radio-style input:checked + label::before {
  border: 1px solid #623189;
}
.radio-style input:checked + label::after {
  background: #623189;
}
.radio-style label {
  position: relative;
  padding-left: 1.5em;
  min-height: 1em;
  cursor: pointer;
}
.radio-style label::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 1px solid #C1C1C1;
}
.radio-style label::after {
  content: "";
  display: block;
  position: absolute;
  left: calc(0.2em + 1px);
  top: calc(0.2em + 1px);
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  background: none;
}

.inner {
  padding: 0 20px;
  width: 100%;
  max-width: 1520px;
  margin: 0 auto;
}
@media (min-width: 1920px) {
  .inner {
    width: 1280px;
    padding: 0;
  }
}
@media (min-width: 1020px) {
  .inner.wide {
    width: 100%;
    max-width: unset;
  }
}

.container {
  padding-top: 56px;
  padding-bottom: 100px;
}
.main .container {
  padding-bottom: 0;
}
@media (min-width: 1020px) {
  .container {
    padding-top: 104px;
  }
}

.visual-top {
  position: relative;
  width: 100%;
  height: 600px;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: center;
  transition: none;
  overflow: hidden;
}
@media (min-width: 1020px) {
  .visual-top {
    height: calc(100vh - 104px);
    padding: 0;
    background-attachment: fixed;
    background-size: cover;
  }
}
.visual-top::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.visual-top.main {
  background-image: url("../images/top_bg_main.jpg");
}
.visual-top.value {
  background-image: url("../images/top_bg_value.jpg");
}
.visual-top.inquiry {
  background-image: url("../images/top_bg_inquiry.jpg");
}
.visual-top.partnership {
  background-image: url("../images/top_bg_partnership.jpg");
}
.visual-top.cleaning {
  background-image: url("../images/top_bg_cleaning.jpg");
}
.visual-top.cleaning2 {
  background-image: url("../images/top_bg_cleaning2.png");
}
.visual-top.cleaning3 {
  background-image: url("../images/top_bg_cleaning3.png");
}
.visual-top.cleaning4 {
  background-image: url("../images/top_bg_cleaning4.png");
}
.visual-top .video {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
}
.visual-top .video video {
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.visual-top .inner {
  position: relative;
  display: flex;
  height: inherit;
  position: relative;
  align-items: center;
  justify-content: center;
  z-index: 3;
}
@media (min-width: 1020px) {
  .visual-top .inner {
    justify-content: flex-start;
  }
}
.visual-top .inner .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 2;
  text-align: center;
  color: #fff;
}
@media (min-width: 1020px) {
  .visual-top .inner .box {
    width: auto;
    text-align: left;
    align-items: flex-start;
  }
}
.visual-top .inner .box p,
.visual-top .inner .box h2,
.visual-top .inner .box .button-area {
  animation-duration: 1s;
  animation-name: textup;
  transform: translateY(10px);
  animation-fill-mode: forwards;
  opacity: 0;
}
.visual-top .inner .box p {
  font-size: clamp(14px, 4vw, 20px);
  line-height: 1.571em;
  margin-bottom: 4px;
  animation-delay: 0s;
}
@media (min-width: 1020px) {
  .visual-top .inner .box p {
    margin-bottom: 20px;
  }
}
@media (min-width: 1920px) {
  .visual-top .inner .box p {
    font-size: clamp(20px, 1.05vw, 40px);
  }
}
.visual-top .inner .box h2 {
  font-family: "inter";
  font-size: clamp(36px, 10vw, 80px);
  font-weight: 900;
  animation-delay: 0.1s;
}
@media (min-width: 1020px) {
  .visual-top .inner .box h2 {
    font-size: 96px;
  }
}
@media (min-width: 1920px) {
  .visual-top .inner .box h2 {
    font-size: clamp(70px, 3vw, 192px);
  }
}
.visual-top .inner .box .button-area {
  display: none;
  animation-delay: 0.2s;
}
@media (min-width: 1020px) {
  .visual-top .inner .box .button-area {
    display: block;
    margin-top: 70px;
  }
}

.floating-menu {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 800;
}
.floating-menu ul li {
  margin-bottom: 16px;
  height: 45px;
  line-height: 45px;
  padding: 0 20px;
  border-radius: 45px;
  font-size: 15px;
  color: #fff;
}
.floating-menu ul li i {
  width: 30px;
  padding-right: 10px;
}
.floating-menu ul li i img {
  max-width: 100%;
  vertical-align: middle;
}
.floating-menu ul li.floating-menu-item1 {
  background: #FFE812;
  color: #3D2803;
}
.floating-menu ul li.floating-menu-item2 {
  background: #03A864;
}
.floating-menu ul li.floating-menu-item3 {
  background: #623189;
}
@media (max-width: 767px) {
  .floating-menu ul li span{
    display: none;
  }
  .floating-menu ul li {
    padding: 0 5px;
  }
  .floating-menu ul li i {
    width: 20px;
    padding-right: 0px;
  }
  .floating-menu ul li i img {
    padding: 3px;
    width: 33px;
  }
  
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
  background: #fff;
  z-index: 998;
}
@media (min-width: 1020px) {
  .header {
    height: 104px;
  }
}
.header .inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: inherit;
}
@media (min-width: 1020px) {
  .header .inner {
    padding: 0 clamp(10px, 1vw, 20px);
    max-width: unset;
    width: auto;
  }
}
@media (min-width: 1920px) {
  .header .inner {
    padding: 0 50px;
  }
}
.header .inner .header-logo {
  flex: 0 1 auto;
  width: 150px;
  height: 44px;
  background: url("../images/common/logo.png") center no-repeat;
  background-size: 100%;
}
@media (min-width: 1020px) {
  .header .inner .header-logo {
    min-width: 88px;
    width: 200px;
    height: 44px;
    margin-left: 0;
    transform: translateY(-4px);
  }
}
.header .inner .header-logo span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.header .inner .btn-header {
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  background: url("../images/common/btn_gnb.svg") center no-repeat;
  background-size: 35%;
  transform: translateX(10px);
}
@media (min-width: 1020px) {
  .header .inner .btn-header {
    background-size: 70%;
  }
}
@media (min-width: 1020px) {
  .header .inner .btn-header {
    display: none;
  }
}
.header .inner .btn-header span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.header .inner .gnb {
  display: none;
  height: inherit;
}
@media (min-width: 1020px) {
  .header .inner .gnb {
    display: flex;
    flex: 1 1 auto;
  }
}
.header .inner .gnb.active {
  display: block;
}
.header .inner .gnb .top-nav {
  height: inherit;
  flex: 1 1 auto;
}
.header .inner .gnb .top-nav .navi-list {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.header .inner .gnb .top-nav .navi-list a {
  padding: 8px;
}
@media (min-width: 1020px) {
  .header .inner .gnb .top-nav .navi-list a {
    font-size: clamp(13px, 4vw, 19px);
    padding: 8px 1.5vw;
  }
}
@media (min-width: 1920px) {
  .header .inner .gnb .top-nav .navi-list a {
    font-size: 21px;
    padding: 8px 25px;
  }
}
.header .inner .gnb .top-nav .navi-list a span {
  white-space: nowrap;
  font-weight: 700;
  color: #222;
}
.header .inner .gnb .utility-nav {
  flex: 0 1 auto;
  height: inherit;
}
.header .inner .gnb .utility-nav ul {
  display: flex;
  height: inherit;
  align-items: center;
  justify-content: center;
}
.header .inner .gnb .utility-nav ul li:last-child {
  margin: 0;
}
@media (min-width: 1020px) {
  .header .inner .gnb .utility-nav ul li {
    margin: 0 14px;
  }
}
.header .inner .gnb .utility-nav ul li a span {
  white-space: nowrap;
  color: #777;
}
.header .inner .gnb .utility-nav ul li button span {
  white-space: nowrap;
}

.app:not(.main) .utility-nav .button-primary {
  background: none;
  color: #777;
}

.mobile-header {
  display: none;
}
.mobile-header.active {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  overflow: hidden auto;
}
@media (min-width: 1020px) {
  .mobile-header.active {
    display: none;
  }
}
.mobile-header .mobile-header-inner {
  display: flex;
  min-height: 100%;
  width: 100%;
  padding: 0 20px 20px;
  flex-direction: column;
  justify-content: space-between;
}
.mobile-header .mobile-header-inner .mobile-header-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 56px;
}
.mobile-header .mobile-header-inner .mobile-header-top .header-logo {
  flex: 0 1 auto;
  width: 88px;
  height: 22px;
  background: url("../images/common/logo.svg") center no-repeat;
  background-size: 100%;
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .header-logo {
    min-width: 88px;
    width: 176px;
    height: 44px;
    margin-left: 0;
    transform: translateY(-4px);
  }
}
.mobile-header .mobile-header-inner .mobile-header-top .header-logo span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.mobile-header .mobile-header-inner .mobile-header-top .btn-close {
  flex: 0 0 42px;
  width: 42px;
  height: 42px;
  background: url("../images/common/btn_close.svg") center no-repeat;
  background-size: 35%;
  transform: translateX(10px);
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .btn-close {
    background-size: 70%;
  }
}
@media (min-width: 1020px) {
  .mobile-header .mobile-header-inner .mobile-header-top .btn-close {
    display: none;
  }
}
.mobile-header .mobile-header-inner .mobile-header-top .btn-close span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.mobile-header .mobile-header-inner .mobile-gnb {
  flex: 1 1 auto;
  padding: 20px;
  font-size: 14px;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list {
  margin-bottom: 50px;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub .depth1 {
  background: url("../images/common/btn_mobile_close.svg") right center no-repeat;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub .navi-sub-list {
  display: none;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub.active .depth1 {
  background: url("../images/common/btn_mobile_more.svg") right center no-repeat;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .navi-item.has-sub.active .navi-sub-list {
  display: block;
  padding: 20px 0;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .btn-navi {
  display: block;
  width: 100%;
  text-align: left;
  padding: 20px 0;
  color: #000;
}
.mobile-header .mobile-header-inner .mobile-gnb .navi-list .btn-navi.depth2 {
  padding: 10px 0;
  color: #777;
}
.mobile-header .mobile-header-inner .mobile-gnb .mobile-utility .button-primary {
  display: block;
  width: 100%;
  background-color: #222;
  line-height: 44px;
}
.mobile-header .mobile-header-inner .mobile-navi-copy {
  font-size: 12px;
  color: 999;
  text-align: center;
}

.footer {
  padding: 84px 16px 68px;
  background: #222;
  color: #C1C1C1;
  font-size: 16px;
}
@media (min-width: 1020px) {
  .footer {
    font-size: 19px;
  }
}
.footer .inner {
  width: 100%;
  max-width: unset;
}
@media (min-width: 1020px) {
  .footer .inner {
    max-width: 1920px;
  }
}
.footer .inner h2 {
  color: #fff;
  font-size: 0.813em;
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  .footer .inner h2 {
    margin-bottom: 12px;
  }
}
.footer .inner .footer-logo {
  margin-bottom: 40px;
  font-family: "inter";
  font-weight: 400;
  font-size: 24px;
  color: #fff;
}
.footer .inner .footer-data {
  display: flex;
  flex-direction: column;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data {
    flex-direction: row;
  }
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data {
    max-width: 612px;
  }
}
.footer .inner .footer-data .company-data h2 {
  display: none;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data h2 {
    display: block;
  }
}
.footer .inner .footer-data .company-data p {
  word-break: keep-all;
  font-size: 0.75em;
  line-height: 1.667em;
}
.footer .inner .footer-data .company-data p.copy {
  display: none;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .company-data p.copy {
    display: block;
    padding-top: 1.5em;
  }
}
.footer .inner .footer-data .footer-guide {
  padding: 42px 0;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-guide {
    flex: 0 0 300px;
    padding: 0;
    margin-right: clamp(40px, 14%, 150px);
    margin-left: clamp(40px, 14%, 285px);
  }
}
.footer .inner .footer-data .footer-guide h2 {
  margin-bottom: 4px;
}
.footer .inner .footer-data .footer-guide ul li {
  border-bottom: 1px solid #999999;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-guide ul li {
    background: url("../images/common/footer_arrow.png") right center no-repeat;
  }
}
.footer .inner .footer-data .footer-guide ul li a {
  display: block;
  padding: 16px 0;
  font-size: 0.75em;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .footer-contact {
    flex: 0 0 182px;
  }
}
.footer .inner .footer-data .footer-contact ul li {
  margin-bottom: 8px;
  font-size: 0.75em;
}
.footer .inner .footer-data .bottom-copy {
  font-size: 0.75em;
  padding-top: 40px;
}
@media (min-width: 1020px) {
  .footer .inner .footer-data .bottom-copy {
    display: none;
  }
}

.form-item {
  display: flex;
  padding: 12px 0;
  border-bottom: 0 solid #C1C1C1;
}
.form-item label {
  position: relative;
  width: 80px;
  padding-right: 20px;
}
.form-item label.star::after {
  content: "";
  display: inline-block;
  width: 4px;
  height: 4px;
  margin-left: 10px;
  border-radius: 50%;
  background: #444;
  vertical-align: middle;
}
.form-item .input-unit {
  display: flex;
  flex-direction: column;
  width: 74%;
}
.form-item .input-unit input {
  flex: 0 0 auto;
  line-height: inherit;
  border-bottom: 1px solid #C1C1C1;
}
.form-item .input-unit input::-moz-placeholder {
  letter-spacing: 0;
  color: #999;
}
.form-item .input-unit input::placeholder {
  letter-spacing: 0;
  color: #999;
}
.form-item .input-unit .error-message {
  color: red;
  font-size: 12px;
  text-align: left;
  margin-left: 0px;
}
.form-item select {
  flex: 1 0 auto;
  line-height: inherit;
  padding: 3px;
  border: 1px solid #c1c1c1;
}
.form-item textarea {
  flex: 1 0 auto;
  min-height: calc(7em + 14px);
  padding: 10px;
  border: 1px solid #c1c1c1;
}
.form-item textarea::-moz-placeholder {
  color: #D1D1D1;
}
.form-item textarea::placeholder {
  color: #D1D1D1;
}

.main-section-consulting {
  position: relative;
  padding: 50px 0;
  overflow: hidden;
}
.main-section-consulting .inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner {
    justify-content: space-between;
    align-items: center;
    height: 985px;
  }
}
.main-section-consulting .inner .text-area {
  text-align: center;
  margin-bottom: 86px;
  width: 100%;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .text-area {
    display: flex;
    position: relative;
    width: 600px;
    height: 640px;
    text-align: left;
    margin-bottom: 0;
    background: #fff;
    z-index: 3;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
.main-section-consulting .inner .text-area h2 {
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .text-area h2 {
    font-size: 48px;
  }
}
.main-section-consulting .inner .text-area .ment {
  font-size: 0.938em;
  margin-bottom: 30px;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .text-area .ment {
    font-size: 30px;
  }
}
.main-section-consulting .inner .text-area p {
  width: 85%;
  word-break: keep-all;
  margin-left: auto;
  margin-right: auto;
  color: #777;
  font-size: 0.813em;
  line-height: 1.385em;
  margin-bottom: 15px;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .text-area p {
    width: 100%;
    font-size: 18px;
    margin-left: 0;
    margin-bottom: 15px;
  }
}
.main-section-consulting .inner .text-area p + p {
  /* padding-top: 1em; */
  margin-bottom: 15px;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .text-area p + p {
    /* padding-top: 0.25em; */
    margin-bottom: 15px;
  }
}
.main-section-consulting .inner .consulting-slider {
  text-align: center;
}

@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider {
    position: absolute;
    width: calc(100vw - 500px);
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
.main-section-consulting .inner .consulting-slider .swiper {
  overflow: visible;
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-wrapper{
  display: -webkit-inline-box;
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-slide {
  width: 100vw;
  text-align: center;
  font-size: 18px;
  background: transparent !important;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 0;
  transition: opacity 0.4s;
}

@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .swiper .swiper-slide {
    width: 440px;
  }
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-slide.swiper-slide-active {
  opacity: 1;
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-slide.swiper-slide-next {
  opacity: 0.4;
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-slide.swiper-slide-prev {
  opacity: 0;
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-slide img {
  width: 50%;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .swiper .swiper-slide img {
    width: 100%;
  }
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-button-prev,
.main-section-consulting .inner .consulting-slider .swiper .swiper-button-next {
  top: 120px;
  left: 50%;
  right: auto;
  border-color: #222;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .swiper .swiper-button-prev,
  .main-section-consulting .inner .consulting-slider .swiper .swiper-button-next {
    top: 220px;
    z-index: 999999999;
  }
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-button-prev {
  transform: translateX(-42vw);
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .swiper .swiper-button-prev {
    transform: translateX(-280px);
  }
}
.main-section-consulting .inner .consulting-slider .swiper .swiper-button-next {
  transform: translateX(35vw);
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .swiper .swiper-button-next {
    transform: translateX(250px);
  }
}
.main-section-consulting .inner .consulting-slider .box div {
  margin-bottom: 30px;
}
.main-section-consulting .inner .consulting-slider .box span {
  display: block;
  margin-bottom: 8px;
  text-align: center;
  color: #777;
  font-size: 0.75em;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .box span {
    font-size: 16px;
  }
}
.main-section-consulting .inner .consulting-slider .box h3 {
  font-size: 1.125em;
  margin-bottom: 25px;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .box h3 {
    font-size: 30px;
    margin-bottom: 46px;
  }
}
.main-section-consulting .inner .consulting-slider .box p {
  color: #777;
}
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .box p {
    font-size: 20px;
    line-height: 30px;
  }
}

.main-section-service {
  display: flex;
  background: #F3F0EB;
}
.main-section-service .inner {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  justify-content: flex-start;
  width: 100%;
  max-width: unset;
}
.main-section-service .inner .img-area {
  width: 100%;
}
@media (min-width: 1020px) {
  .main-section-service .inner .img-area {
    width: 50%;
  }
}
.main-section-service .inner .img-area img {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.main-section-service .inner .text-area {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 auto;
  width: 100%;
  padding: 43px 20px 50px;
}
@media (min-width: 1020px) {
  .main-section-service .inner .text-area {
    width: 50%;
    height: 100%;
    padding-left: clamp(60px, 8%, 150px);
    padding-right: 0;
  }
}
.main-section-service .inner .text-area h2 {
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .main-section-service .inner .text-area h2 {
    font-size: 48px;
  }
}
.main-section-service .inner .text-area .ment {
  max-width: 70%;
  margin-bottom: 35px;
  font-size: 0.938em;
  line-height: 1.333em;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .main-section-service .inner .text-area .ment {
    max-width: 100%;
    font-size: 30px;
  }
}
.main-section-service .inner .text-area p {
  width: 90%;
  font-size: 0.813em;
  margin-bottom: 15px;
}
@media (min-width: 1020px) {
  .main-section-service .inner .text-area p {
    max-width: 100%;
    font-size: 18px;
    margin-bottom: 15px;
  }
}

.main-section-service .inner .text-area p + p {
  margin-bottom: 26px;
}
@media (min-width: 1020px) {
  .main-section-service .inner .text-area p + p {
    margin-bottom: 15px;
  }
}
.main-section-service .inner .text-area button {
  flex: 0 0 auto;
}

.main-section-system {
  padding: 43px 0;
  text-align: center;
}
@media (min-width: 1020px) {
  .main-section-system {
    padding: 150px 0 0;
    text-align: left;
  }
}
.main-section-system .text-area h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 700;
}
@media (min-width: 1020px) {
  .main-section-system .text-area h2 {
    font-size: 48px;
  }
}
.main-section-system .text-area .subment {
  margin-bottom: 26px;
  color: #999999;
}
@media (min-width: 1020px) {
  .main-section-system .text-area .subment {
    font-size: 18px;
    margin-bottom: 38px;
  }
}
.main-section-system .text-area p {
  padding: 0 30px 0 0;
  font-size: 0.813em;
  line-height: 1.385em;
  word-break: keep-all;
  margin-bottom: 24px;
}
@media (min-width: 1020px) {
  .main-section-system .text-area p {
    font-size: 20px;
    margin-bottom: 50px;
  }
}
.main-section-system .system-list {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  justify-content: space-between;
}
.main-section-system .system-list li {
  margin-bottom: 34px;
  width: 100%;
}
.main-section-system .system-list li:last-child {
  margin: 0;
}
@media (min-width: 1020px) {
  .main-section-system .system-list li {
    margin-right: 20px;
    width: calc(50% - 20px);
  }
}
.main-section-system .system-list li .system-list-img {
  width: 100%;
  margin-bottom: 24px;
  border-radius: 10px;
}
.main-section-system .system-list li .system-list-img img {
  width: 100%;
}
.main-section-system .system-list li .system-list-box h3 {
  margin-bottom: 15px;
  font-size: 1em;
  font-weight: 700;
}
@media (min-width: 1020px) {
  .main-section-system .system-list li .system-list-box h3 {
    font-size: 24px;
  }
}
.main-section-system .system-list li .system-list-box p {
  width: 90%;
  word-break: keep-all;
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .main-section-system .system-list li .system-list-box p {
    width: 100%;
    font-size: 16px;
  }
}

.main-section-provide {
  background: #F7F7F7;
  margin-top: 200px;
  padding-bottom: 24px;
}
@media (min-width: 1020px) {
  .main-section-provide {
    margin-top: clamp(100px, 20vw, 340px);
  }
}
.main-section-provide .inner {
  position: relative;
  padding-top: 200px;
}
@media (min-width: 1020px) {
  .main-section-provide .inner {
    padding-top: 88px;
    padding-bottom: 200px;
  }
}
.main-section-provide .inner .provide-img {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 375px;
  padding: 0 20px;
}
@media (min-width: 1020px) {
  .main-section-provide .inner .provide-img {
    width: 38%;
    max-width: 1176px;
    left: unset;
    right: 0;
    transform: translate(0, -50%);
  }
}
.main-section-provide .inner .provide-img img {
  width: 100%;
}
.main-section-provide .inner .provide-box .ment1 {
  width: 80%;
  word-break: keep-all;
  font-size: 1.125em;
  font-weight: 900;
  margin-bottom: 16px;
}
@media (min-width: 1020px) {
  .main-section-provide .inner .provide-box .ment1 {
    width: 40%;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 32px;
  }
}
.main-section-provide .inner .provide-box .ment2 {
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .main-section-provide .inner .provide-box .ment2 {
    width: 60%;
    font-size: 18px;
    line-height: 30px;
  }
}

[class^=value-section-] {
  margin: 60px 0;
}
@media (min-width: 1020px) {
  [class^=value-section-] {
    margin: 120px 0;
  }
}
[class^=value-section-] h2 {
  font-size: 1.5em;
  line-height: 1.667em;
  font-weight: 700;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  [class^=value-section-] h2 {
    font-size: 48px;
  }
}
[class^=value-section-] .ment {
  font-size: 0.938em;
  line-height: 1.5em;
}
@media (min-width: 1020px) {
  [class^=value-section-] .ment {
    font-size: 24px;
    line-height: 36px;
  }
}

.value-section-consulting {
  position: relative;
  padding: 50px 0;
  overflow: hidden;
}
.value-section-consulting .inner .text-area {
  margin-bottom: 100px;
  text-align: center;
  width: 100%;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .text-area {
    position: relative;
    padding-left: 400px;
    margin-bottom: 40px;
    text-align: left;
  }
}
.value-section-consulting .inner .text-area h2 {
  line-height: 2em;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .text-area h2 {
    position: absolute;
    left: 0;
    font-size: 48px;
  }
}
.value-section-consulting .inner .text-area .ment {
  width: 80%;
  margin: 0 auto 30px;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .text-area .ment {
    width: 100%;
    padding-right: 20px;
    margin-bottom: 24px;
  }
}
.value-section-consulting .inner .text-area p {
  width: 85%;
  word-break: keep-all;
  margin-left: auto;
  margin-right: auto;
  color: #777;
  font-size: 0.813em;
  line-height: 1.538em;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .text-area p {
    width: 100%;
    padding-right: 20px;
    font-size: 16px;
    margin-left: 0;
  }
}
.value-section-consulting .inner .consulting-list ul {
  display: flex;
  flex-wrap: wrap;
  padding: calc(33.33vw + 30px) 10px 0;
  background: url(../images/value/consulting_top.png) center 0 no-repeat;
  background-size: contain;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .consulting-list ul {
    max-width: 1520px;
    margin: 0 auto;
    padding-top: 0;
    background: url(../images/value/consulting_bg.png) center no-repeat;
    background-size: contain;
  }
}
.value-section-consulting .inner .consulting-list ul li {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 40px;
  margin-bottom: 50px;
  text-align: center;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .consulting-list ul li {
    flex: 1 1 33.33%;
    height: 33.33vw;
    margin-bottom: 20px;
  }
}
@media (min-width: 1920px) {
  .value-section-consulting .inner .consulting-list ul li {
    padding: 0 80px;
    height: 530px;
  }
}
.value-section-consulting .inner .consulting-list ul li i {
  height: 70px;
  margin-bottom: 20px;
}
.value-section-consulting .inner .consulting-list ul li i img {
  height: 100%;
}
.value-section-consulting .inner .consulting-list ul li h3 {
  text-decoration: underline;
  margin-bottom: 15px;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .consulting-list ul li h3 {
    font-size: clamp(20px, 2vw, 28px);
    margin-bottom: 30px;
  }
}
@media (min-width: 1920px) {
  .value-section-consulting .inner .consulting-list ul li h3 {
    font-size: 28px;
  }
}
.value-section-consulting .inner .consulting-list ul li p {
  color: #444;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .value-section-consulting .inner .consulting-list ul li p {
    font-size: clamp(14px, 1.5vw, 18px);
    line-height: 30px;
  }
}
@media (min-width: 1920px) {
  .value-section-consulting .inner .consulting-list ul li p {
    font-size: 18px;
    line-height: 30px;
  }
}

.value-section-service {
  margin: 100px 0;
}
.value-section-service .inner {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  align-items: stretch;
}
.value-section-service .inner h2 {
  width: 100%;
}
@media (min-width: 1020px) {
  .value-section-service .inner h2 {
    text-align: left;
  }
}
.value-section-service .inner .img-area {
  position: relative;
  flex: 0 0 100%;
  height: 100vw;
  background: url("../images/value/pyramid_m.png") center no-repeat;
  background-size: contain;
}
@media (min-width: 1020px) {
  .value-section-service .inner .img-area {
    flex: 0 0 45%;
    height: auto;
    background: url("../images/value/pyramid.png") center no-repeat;
    background-size: contain;
  }
}
.value-section-service .inner .text-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (min-width: 1020px) {
  .value-section-service .inner .text-area {
    flex: 0 0 55%;
    text-align: left;
    transform: translateY(-36px);
    padding-left: 80px;
  }
}
.value-section-service .inner .text-area .ment {
  padding: 0 40px 100px;
  word-break: keep-all;
  font-weight: 700;
}
@media (min-width: 1020px) {
  .value-section-service .inner .text-area .ment {
    padding: 0 0 30px 0;
  }
}
.value-section-service .inner .text-area .service-list li {
  padding: 30px 10px 30px 36px;
  border-top: 1px solid #D9D9D9;
  text-align: left;
}
.value-section-service .inner .text-area .service-list li:last-child {
  border-bottom: 1px solid #D9D9D9;
}
.value-section-service .inner .text-area .service-list li h3 {
  position: relative;
  font-size: 1.125em;
  margin-bottom: 15px;
}
@media (min-width: 1020px) {
  .value-section-service .inner .text-area .service-list li h3 {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.value-section-service .inner .text-area .service-list li h3:before {
  content: "";
  display: block;
  position: absolute;
  left: -30px;
  top: 6px;
  width: 17px;
  height: 18px;
  background: url("../images/value/bullet.png");
}
.value-section-service .inner .text-area .service-list li h3 span {
  color: #623189;
}
.value-section-service .inner .text-area .service-list li p {
  color: #444;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .value-section-service .inner .text-area .service-list li p {
    font-size: 18px;
    line-height: 28px;
  }
}
@media (min-width: 1920px) {
  .value-section-service .inner .text-area .service-list li p {
    padding-right: 140px;
  }
}

.value-section-system {
  padding: 100px 0;
  text-align: center;
}
.value-section-system .text-area {
  position: relative;
}
@media (min-width: 1020px) {
  .value-section-system .text-area {
    padding-left: 400px;
    text-align: left;
  }
}
.value-section-system .text-area h2 {
  width: 100%;
  margin-bottom: 16px;
}
@media (min-width: 1020px) {
  .value-section-system .text-area h2 {
    position: absolute;
    left: 0;
    font-size: 48px;
  }
}
.value-section-system .text-area .ment {
  margin-bottom: 26px;
}
@media (min-width: 1020px) {
  .value-section-system .text-area .ment {
    margin-bottom: 33px;
  }
}
.value-section-system .text-area p {
  color: #444;
  font-size: 0.813em;
  line-height: 1.538em;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .value-section-system .text-area p {
    padding-right: 40px;
    font-size: 16px;
    line-height: 30px;
  }
}
.value-section-system .img-area {
  position: relative;
  margin-top: 50px;
}
@media (min-width: 1920px) {
  .value-section-system .img-area .img-box {
    margin: 0 -100px;
  }
}
.value-section-system .img-area .img-box img {
  width: 100%;
}
.value-section-system .img-area .text-box {
  text-align: left;
  padding: 25px 0;
  background-color: #fff;
}
@media (min-width: 1020px) {
  .value-section-system .img-area .text-box {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 34px 40px;
  }
}
.value-section-system .img-area .text-box .title {
  margin-bottom: 13px;
  font-size: 0.813em;
  line-height: 1.154em;
  color: #999;
}
@media (min-width: 1020px) {
  .value-section-system .img-area .text-box .title {
    font-size: 16px;
  }
}
.value-section-system .img-area .text-box .ment {
  word-break: keep-all;
  font-size: 1rem;
  line-height: 1.375em;
}
@media (min-width: 1020px) {
  .value-section-system .img-area .text-box .ment {
    font-size: 18px;
    line-height: 30px;
  }
}

.inquiry-section h2 {
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;
  text-align: center;
  padding: 40px 0 24px;
}
@media (min-width: 1020px) {
  .inquiry-section h2 {
    font-size: 48px;
    padding: 100px 0;
  }
}

.form-area {
  background: #F7F7F7;
  margin-left: -20px;
  margin-right: -20px;
  padding: 40px 20px 80px;
}
@media (min-width: 1020px) {
  .form-area {
    margin: 0 auto 200px;
    max-width: 960px;
    padding: 100px 150px 140px;
  }
}
.form-area h3 {
  padding-left: 10px;
  font-size: 1.125em;
  line-height: 1.333em;
  font-weight: 400;
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  .form-area h3 {
    padding-left: 0;
    font-size: 36px;
    margin-bottom: 90px;
  }
}
.form-area .writting-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}
.form-area .writting-list .form-item {
  width: 100%;
  font-size: 0.813em;
  line-height: 1.154em;
  margin-bottom: 14px;
}
@media (min-width: 1020px) {
  .form-area .writting-list .form-item {
    font-size: 1em;
  }
}
@media (min-width: 1020px) {
  .form-area .writting-list .form-item.text {
    width: 46%;
  }
}
.form-area .agree-area {
  margin-bottom: 40px;
  font-size: 0.75em;
  color: #999999;
}
@media (min-width: 1020px) {
  .form-area .agree-area {
    font-size: 14px;
    margin-bottom: 60px;
  }
}
.form-area .button-area button {
  width: 100%;
}

[class^=cleaning-section-] {
  padding-top: 50px;
  padding-bottom: 50px;
}
@media (min-width: 1020px) {
  [class^=cleaning-section-] {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
[class^=cleaning-section-] .text-area {
  padding-bottom: 100px;
}
[class^=cleaning-section-] .text-area h2 {
  font-size: 1.5em;
  line-height: 1.667em;
  font-weight: 700;
  word-break: keep-all;
  text-align: center;
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  [class^=cleaning-section-] .text-area h2 {
    font-size: 48px;
    margin-bottom: 36px;
  }
}
[class^=cleaning-section-] .text-area .ment {
  font-size: 0.938em;
  line-height: 1.5em;
  text-align: center;
  word-break: keep-all;
  color: #444;
}
@media (min-width: 1020px) {
  [class^=cleaning-section-] .text-area .ment {
    font-size: 18px;
    line-height: 36px;
  }
}

.cleaning-section-needs {
  position: relative;
}
@media (min-width: 1020px) {
  .cleaning-section-needs .inner {
    padding-left: 0;
    padding-right: 0;
  }
}
.cleaning-section-needs .img-box {
  display: flex;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}
@media (min-width: 1020px) {
  .cleaning-section-needs .img-box {
    margin-left: 0;
    margin-right: 0;
  }
}
.cleaning-section-needs .img-box > div {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
  height: 100vw;
  padding: 50px 20px;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box > div {
    flex: 0 0 50%;
    height: 514px;
    justify-content: space-between;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-needs .img-box > div {
    padding: 50px;
  }
}
.cleaning-section-needs .img-box > div img {
  position: absolute;
  max-width: 40%;
  height: auto;
  max-height: 750px;
}
.cleaning-section-needs .img-box > div span {
  font-size: 0.938em;
  line-height: 1.5em;
  color: #777777;
}
.cleaning-section-needs .img-box > div p {
  position: relative;
  width: 60%;
  padding: 2em 1em 0.5em 2em;
  word-break: keep-all;
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  .cleaning-section-needs .img-box > div p {
    text-align: center !important;
  }
}
.cleaning-section-needs .img-box > div p::before, .cleaning-section-needs .img-box > div p::after {
  font-size: 3em;
  display: block;
  position: absolute;
}
.cleaning-section-needs .img-box > div p::before {
  content: "“";
  left: 0;
  top: 0.25em;
}
.cleaning-section-needs .img-box > div p::after {
  content: "”";
  right: 0;
  bottom: 0;
  transform: translateY(1.25em);
}
.cleaning-section-needs .img-box.type1 .img-box-left {
  background: #F7F7F7;
  justify-content: flex-start;
}
@media (max-width: 767px) {
  .cleaning-section-needs .img-box.type1 .img-box-left {
    height: auto;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-left {
    background: #F7F7F7;
    justify-content: space-between;
  }
}
.cleaning-section-needs .img-box.type1 .img-box-left img {
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .cleaning-section-needs .img-box.type1 .img-box-left img {
    position: relative;
  }
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-left img {
    left: 0;
    transform: translateX(0);
  }
}
.cleaning-section-needs .img-box.type1 .img-box-left span {
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-left span {
    text-align: right;
  }
}
.cleaning-section-needs .img-box.type1 .img-box-left p {
  width: 90%;
  text-align: center;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-left p {
    width: 60%;
    text-align: right;
  }
}
.cleaning-section-needs .img-box.type1 .img-box-right {
  flex-direction: column;
  text-align: left;
  background: #fff;
}
@media (max-width: 767px) {
  .cleaning-section-needs .img-box.type1 .img-box-right {
    height: auto;
    padding-bottom: 0;
  }
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-right {
    background: #fff;
    flex-direction: column-reverse;
  }
}
.cleaning-section-needs .img-box.type1 .img-box-right img {
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
@media (max-width: 767px) {
  .cleaning-section-needs .img-box.type1 .img-box-right img {
    position: relative;
  }
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-right img {
    left: unset;
    right: 0;
    transform: translateX(0);
  }
}
.cleaning-section-needs .img-box.type1 .img-box-right span {
  display: block;
  text-align: center;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-right span {
    text-align: left;
  }
}
.cleaning-section-needs .img-box.type1 .img-box-right p {
  width: 90%;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type1 .img-box-right p {
    width: 60%;
    margin-left: unset;
    text-align: left;
  }
}
.cleaning-section-needs .img-box.type2 .img-box-left {
  background: #F7F7F7;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type2 .img-box-left {
    background: #fff;
  }
}
.cleaning-section-needs .img-box.type2 .img-box-left img {
  left: 0;
  bottom: 0;
}
.cleaning-section-needs .img-box.type2 .img-box-left span {
  display: block;
  text-align: right;
}
.cleaning-section-needs .img-box.type2 .img-box-left p {
  margin-left: auto;
  text-align: right;
}
.cleaning-section-needs .img-box.type2 .img-box-right {
  flex-direction: column;
  text-align: left;
  background: #fff;
}
@media (min-width: 768px) {
  .cleaning-section-needs .img-box.type2 .img-box-right {
    background: #F7F7F7;
    flex-direction: column-reverse;
  }
}
.cleaning-section-needs .img-box.type2 .img-box-right img {
  right: 0;
  bottom: 0;
}

.cleaning-section-circle .img-area {
  margin-bottom: 96px;
  text-align: center;
}
.cleaning-section-circle .img-area img {
  width: 100%;
  max-width: 580px;
}
.cleaning-section-circle .cleaning-circle-list {
  margin-bottom: 100px;
}
.cleaning-section-circle .cleaning-circle-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px 0;
}
.cleaning-section-circle .cleaning-circle-list ul li {
  flex: 0 0 100%;
  padding: 18px;
  margin: 10px 20px;
  border: 1px solid #E5E5E5;
  text-align: center;
}
@media (min-width: 1020px) {
  .cleaning-section-circle .cleaning-circle-list ul li {
    margin: 10px;
    padding: 34px 40px;
    flex: 0 0 calc(25% - 20px);
  }
}
.cleaning-section-circle .cleaning-circle-list ul li span {
  display: block;
  color: #623189;
  font-size: 0.688em;
  padding-bottom: 20px;
  margin-bottom: 20px;
  background: url("../images/cleaning/special_bul.png") center bottom no-repeat;
}
@media (min-width: 1020px) {
  .cleaning-section-circle .cleaning-circle-list ul li span {
    font-size: 22px;
  }
}
.cleaning-section-circle .cleaning-circle-list ul li h3 {
  color: #623189;
  font-size: 0.938em;
  font-weight: 900;
  margin-bottom: 16px;
}
@media (min-width: 1020px) {
  .cleaning-section-circle .cleaning-circle-list ul li h3 {
    font-size: 24px;
  }
}
.cleaning-section-circle .cleaning-circle-list ul li p {
  word-break: keep-all;
  font-size: 0.813em;
  line-height: 1.5;
}
@media (min-width: 1020px) {
  .cleaning-section-circle .cleaning-circle-list ul li p {
    font-size: 18px;
    line-height: 1.5;
  }
}

@media (min-width: 1020px) {
  .cleaning-section-system {
    padding: 120px 0;
    background: url("../images/cleaning/feature_bg.jpg") center 0 no-repeat;
    background-size: cover;
  }
}
.cleaning-section-system .inner {
  background: url("../images/cleaning/feature_bg.jpg") center 0 no-repeat;
  background-size: cover;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner {
    display: flex;
    background: none;
    align-items: center;
    justify-content: space-between;
  }
}
.cleaning-section-system .inner .text-area {
  padding: 50px 0;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .text-area {
    flex: 1 1 40%;
    padding-right: 50px;
  }
}
.cleaning-section-system .inner .text-area h2 {
  text-align: center;
  color: #fff;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .text-area h2 {
    text-align: left;
  }
}

.cleaning-section-system .inner .text-area .ment {
  text-align: center;
  color: #fff;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .text-area .ment {
    text-align: left;
  }
}
.cleaning-section-system .inner .cleaning-system-list {
  padding-bottom: 20px;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .cleaning-system-list {
    flex: 0 0 630px;
    padding-bottom: 0;
  }
}
.cleaning-section-system .inner .cleaning-system-list ul {
  position: relative;
  width: 100%;
  height: calc(100vw - 40px);
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .cleaning-system-list ul {
    width: 630px;
    height: 630px;
  }
}
.cleaning-section-system .inner .cleaning-system-list ul span{
  font-size: 13px;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .cleaning-system-list ul span{
    font-size: 18px;
  }
}

.cleaning-section-system .inner .cleaning-system-list ul li {
  position: absolute;
}
.cleaning-section-system .inner .cleaning-system-list ul li.cleaning-system-item {
  display: flex;
  align-items: flex-end;
  width: 50%;
  height: 50%;
  padding: 24px;
  border: 1px solid #fff;
}
@media (min-width: 1020px) {
  .cleaning-section-system .inner .cleaning-system-list ul li.cleaning-system-item {
    padding: 50px;
  }
}
.cleaning-section-system .inner .cleaning-system-list ul li.item1 {
  left: 0;
  top: 0;
  color: #fff;
}
.cleaning-section-system .inner .cleaning-system-list ul li.item2 {
  background: #fff;
  right: 0;
  bottom: 0;
}

.cleaning-section-feature .text-area {
  padding-bottom: 0;
}
.cleaning-section-feature .text-area h2 {
  text-align: left;
}
.cleaning-section-feature .cleaning-feature-list {
  border-top: 1px solid #222;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .title {
  display: flex;
  border-bottom: 1px solid #E5E5E5;
  padding: 0 10px;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .title h3 {
  flex: 1 0 auto;
  padding: 20px 0;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .title button {
  width: 60px;
  padding: 20px 0;
  background: url("../images/cleaning/btn_close.png") center no-repeat;
  background-size: 50%;
  cursor: pointer;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .title button span {
  width: 1px;
  height: 1px;
  overflow: hidden;
  visibility: hidden;
  line-height: 0;
  font-size: 0;
  position: absolute;
  left: -9999px;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content {
  border-bottom: 1px solid #E5E5E5;

}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail ul{
  display: flex;
  flex-wrap: wrap;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list {
  width: 90px;
  margin: 10px 0px;
}

.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list .ico-area{
  display: flex;
  width: 70px;
  height: 70px;
  background: #FFFFFF;
  box-shadow: 2px 2px 10px 0px #0000001A;
  border-radius: 50%;
  margin: auto auto;
}

.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list div img{
  margin: auto auto;
}

.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list .text-area{
  font-family: "one";
  font-size: 13px;
  font-weight: 400;
  text-align: center;
  margin-top: 20px;
}

.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item.active .title button {
  background-image: url("../images/cleaning/btn_open.png");
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item.active .content {
  display: block;
  height: auto;
}
.cleaning-section-feature .service-target-list {
  display: flex;
  flex-wrap: wrap;
}
.cleaning-section-feature .service-target-list .service-target-item {
  position: relative;
  flex: 0 0 25%;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item {
    flex: 0 0 12.5%;
    margin-bottom: 100px;
  }
  .cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list {
    width: 98px;
    margin: 40px 0px;
  }

  .cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list .text-area{
    font-size: 15px;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .icon-area {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: center;
  width: 100%;
  height: 100px;
  background: #F7F7F7;
  cursor: pointer;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item .icon-area {
    height: 213px;
    padding: 60px 0;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .icon-area span {
  padding: 8px 4px 0;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item .icon-area span {
    padding: 18px 40px 0;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .target-box {
  display: none;
  position: absolute;
  width: 400%;
  padding: 24px;
  left: 0;
  top: 0;
  background: #fff;
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="0"] {
  transform: translate(0%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="1"] {
  transform: translate(-25%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="2"] {
  transform: translate(-50%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="3"] {
  transform: translate(-75%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="4"] {
  transform: translate(0%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="5"] {
  transform: translate(-25%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="6"] {
  transform: translate(-50%, 100px);
}
.cleaning-section-feature .service-target-list .service-target-item .target-box[data-index="7"] {
  transform: translate(-75%, 100px);
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item .target-box {
    width: 360px;
    padding: 50px 30px;
    left: 50%;
    top: 0;
    border: 1px solid #E5E5E5;
  }
}
@media (min-width: 1920px) {
  .cleaning-section-feature .service-target-list .service-target-item .target-box {
    width: 500px;
    padding: 50px;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .target-box h4 {
  margin-bottom: 20px;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item .target-box h4 {
    font-size: 24px;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .target-box table {
  color: #777;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item .target-box table {
    font-size: 16px;
  }
}
.cleaning-section-feature .service-target-list .service-target-item .target-box table th {
  padding: 10px 0;
  font-weight: 400;
  text-align: left;
}
.cleaning-section-feature .service-target-list .service-target-item .target-box table td {
  padding: 10px 0;
}
.cleaning-section-feature .service-target-list .service-target-item .target-box table td img{
  width: 18px;
  height: 18px;
}
.cleaning-section-feature .service-target-list .service-target-item.active {
  margin-bottom: 400px;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item.active {
    margin-bottom: 100px;
  }
}
.cleaning-section-feature .service-target-list .service-target-item.active .icon-area {
  position: relative;
  background-color: #623189;
}
.cleaning-section-feature .service-target-list .service-target-item.active .icon-area img {
  filter: invert(100%);
}
.cleaning-section-feature .service-target-list .service-target-item.active .icon-area span {
  color: #fff;
}
.cleaning-section-feature .service-target-list .service-target-item.active .icon-area::after {
  position: absolute;
  content: "";
  display: none;
  width: 0;
  height: 0;
  border-bottom: 50px solid transparent;
  border-top: 50px solid #623189;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 100%);
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item.active .icon-area::after {
    display: block;
  }
}
.cleaning-section-feature .service-target-list .service-target-item.active .target-box {
  display: block;
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item.active .target-box {
    transform: translate(-50%, 280px);
  }
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item.active .target-box[data-index="0"] {
    left: 0;
    transform: translate(0, 280px) !important;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-feature .service-target-list .service-target-item.active .target-box[data-index="7"] {
    left: 100%;
    transform: translate(-100%, 280px) !important;
  }
}

.cleaning-section-step .cleaning-process-list ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px 0;
}
.cleaning-section-step .cleaning-process-list ul li .process-img-box {
  flex: 0 0 100%;
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li .process-img-box {
    flex: 0 0 40%;
  }
}
.cleaning-section-step .cleaning-process-list ul li .process-img-box img {
  width: 100%;
}
.cleaning-section-step .cleaning-process-list ul li .process-text-box {
  flex: 0 0 100%;
  padding: 24px 0;
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li .process-text-box {
    flex: 1 1 60%;
    padding-left: 60px;
  }
}
.cleaning-section-step .cleaning-process-list ul li .process-text-box div {
  font-size: 1.125em;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li .process-text-box div {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.cleaning-section-step .cleaning-process-list ul li .process-text-box p {
  font-size: 0.813em;
  color: #444;
  word-break: keep-all;
  line-height: 1.5;
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li .process-text-box p {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li.process2 {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-step .cleaning-process-list ul li.process2 .process-text-box {
    padding-left: 0;
    padding-right: 60px;
  }
}

.cleaning-section-detail .cleaning-detail-list ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.cleaning-section-detail .cleaning-detail-list ul li {
  padding: 10px;
  flex: 0 0 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (min-width: 1020px) {
  .cleaning-section-detail .cleaning-detail-list ul li {
    flex-basis: auto;
    flex-basis: 14.2857142857%;
  }
}
.cleaning-section-detail .cleaning-detail-list ul li i {
  display: flex;
  width: 80px;
  height: 80px;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}
.cleaning-section-detail .cleaning-detail-list ul li i img {
  width: auto;
  max-height: 80px;
}
.cleaning-section-detail .cleaning-detail-list ul li div {
  text-align: center;
}

.cleaning-section-special .ment {
  margin-bottom: 0;
}
.cleaning-section-special ul li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 24px 0;
}
.cleaning-section-special ul li .process-img-box {
  flex: 0 0 100%;
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li .process-img-box {
    flex: 0 0 40%;
  }
}
.cleaning-section-special ul li .process-img-box img {
  width: 100%;
}
.cleaning-section-special ul li .process-text-box {
  flex: 0 0 100%;
  padding: 24px 0;
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li .process-text-box {
    flex: 1 1 60%;
    padding-left: 60px;
  }
}
.cleaning-section-special ul li .process-text-box i {
  display: inline-block;
  margin-bottom: 40px;
}
.cleaning-section-special ul li .process-text-box i img {
  width: clamp(60px, 4vw, 200px);
}
.cleaning-section-special ul li .process-text-box h3 {
  font-size: 1.125em;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li .process-text-box h3 {
    font-size: 26px;
    margin-bottom: 20px;
  }
}
.cleaning-section-special ul li .process-text-box p {
  font-size: 0.813em;
  color: #444;
  word-break: keep-all;
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li .process-text-box p {
    font-size: 16px;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li:nth-child(2n) {
    flex-direction: row-reverse;
  }
}
@media (min-width: 1020px) {
  .cleaning-section-special ul li:nth-child(2n) .process-text-box {
    padding-left: 0;
    padding-right: 60px;
  }
}


.member-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 100px 20px;
}
@media (min-width: 1020px) {
  .member-section {
    padding: 120px 0 180px;
    min-height: calc(100vh - 493px);
  }
}
.member-section .member-step {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.member-section .member-step .member-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #E5E5E5;
  font-size: 30px;
  margin-bottom: 50px;
}
@media (min-width: 1020px) {
  .member-section .member-step.step1 {
    padding-bottom: 100px;
    border-bottom: 1px solid #E5E5E5;
  }
}
.member-section .member-step.step2 {
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .member-section .member-step.step2 {
    padding-top: 0;
    font-size: 1em;
  }
}
.member-section .member-step.step2 .title-area h2 {
  font-size: 2em;
  line-height: 2em;
  font-weight: 700;
  margin-bottom: 12px;
}
.member-section .member-step.step2 .title-area p {
  font-size: 1em;
}

@media (min-width: 1020px) {
  .member-section .member-step.step2 {
    padding-top: 0px;
    font-size: 1em;
  }
}
.member-section .member-step.step2 [class^=term-] {
  padding: 16px 0;
}
@media (min-width: 1020px) {
  .member-section .member-step.step2 [class^=term-] {
    padding: 30px 0;
  }
}
.member-section .member-step.step2 .term-all {
  border-bottom: 1px solid #E5E5E5;
}
.member-section .member-step.step2 .agree-area + .terms-area {
  margin-top: 20px;
}
.member-section .member-area {
  width: 100%;
}
@media (min-width: 1020px) {
  .member-section .member-area {
    width: 613px;
  }
}
.member-section .member-area .title-area {
  text-align: center;
  margin-bottom: 30px;
}
@media (min-width: 1020px) {
  .member-section .member-area .title-area {
    margin-bottom: 50px;
  }
}
.member-section .member-area .title-area h2 {
  font-size: 1.5em;
  line-height: 2em;
  font-weight: 700;
  margin-bottom: 12px;
}
@media (min-width: 1020px) {
  .member-section .member-area .title-area h2 {
    font-size: 48px;
    margin-bottom: 24px;
  }
}
.member-section .member-area .title-area p {
  word-break: keep-all;
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .member-section .member-area .title-area p {
    font-size: 24px;
    white-space: nowrap;
  }
}
.member-section .member-area .input-area {
  margin-bottom: 0;
}
.member-section .member-area .input-area .input-style {
  margin-bottom: 10px;
  font-size: 0.813em;
  line-height: 1.385em;
}
.member-section .member-area .input-area .input-style:last-child {
  margin-bottom: 0;
}
@media (min-width: 1020px) {
  .member-section .member-area .input-area .input-style.type2 {
    margin-bottom: 20px;
  }
}
@media (min-width: 1020px) {
  .member-section .member-area .input-area .input-style {
    font-size: 1em;
    line-height: 1em;
  }
}
.member-section .member-area .input-area .input-style .tip {
  font-size: 12px;
  margin-top: 10px;
  text-align: left;
}
@media (min-width: 1020px) {
  .member-section .member-area .input-area .input-style .tip {
    font-size: 14px;
  }
}
.member-section .member-area .extra-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.member-section .member-area .extra-area ul {
  display: flex;
}
.member-section .member-area .extra-area ul li {
  margin-left: 20px;
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .member-section .member-area .extra-area ul li {
    font-size: 1em;
  }
}
.member-section .member-area .extra-area ul li:first-child {
  margin-left: 0;
}
.member-section .member-area .terms-area {
  overflow: hidden auto;
  height: 260px;
  padding: 20px;
  border: 10px solid #F7F7F7;
  background: #F7F7F7;
}
@media (min-width: 1020px) {
  .member-section .member-area .terms-area {
    font-size: 14px;
    line-height: 20px;
  }
}
.member-section .member-area .terms-area::-webkit-scrollbar {
  width: 8px;
  background: none;
}
.member-section .member-area .terms-area::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(190, 190, 190, 0.8);
}
.member-section .member-area .button-area {
  margin-top: 40px;
  margin-bottom: 20px;
}
.member-section .member-area .button-area button {
  display: block;
  width: 100%;
}
.member-section .member-area .twobutton-area {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.member-section .member-area .twobutton-area button {
  display: block;
  width: 48%;
}

.business-gallery {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}
@media (min-width: 1020px) {
  .business-gallery {
    padding: 0 0px;
  }
}
.business-gallery .write-btn {
  width: 150px;
  justify-content: end;
  padding: 13px;
}
.business-gallery .btn-area {
  display: flex;
  justify-items: start;
  padding-top: 20px;
}
.business-gallery .btn-area .divide{
  padding: 0px 10px;
}
.business-gallery .input-area{
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.business-gallery .quill {
  height: 600px;
  margin-bottom: 40px;
  font-size: 35px;
}
.business-gallery ul {
  display: flex;
  flex-wrap: wrap;  
  justify-content: center;
}
.business-gallery ul li {
  position: relative;
  margin: 8px;
  flex: 0 0 auto;
  width: calc(50% - 16px);
  border: 1px solid #F5F5F5;
}
.business-gallery ul li::after {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
}
@media (min-width: 1020px) {
  .business-gallery ul li {
    width: calc(25% - 16px);
  }
}
@media (min-width: 1920px) {
  .business-gallery ul li {
    width: 302px;
    height: 302px;
  }
}
.business-gallery ul li div {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
}
.business-gallery ul li div span {
  display: block;
  width: 100%;
  text-align: start;
  color: #000000;
  font-size: 0.688em;
  line-height: 1.2;
  padding: 10px;
}
.business-gallery ul li div a h3 {
  display: none;
}

@media (min-width: 1020px) {
  .business-gallery ul li div span {
    font-size: 16px;
  }
}

.business-gallery ul li div img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-height: 35vw;
}

@media (min-width: 1020px) {
  .business-gallery ul li div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-height: 13vw;
  }
}

.business-gallery ul li div h3 {
  display: none;
}

ul.paging { 
  width:100%;
  height: 16px;
	text-align:center;
	margin:50px 0px 200px 0px;
}

ul.paging li { 
  width: auto;
	display:inline-block;
	margin:0px 10px;
}

ul.paging li a {
  font-family: 'Lato';
  font-size: 16px;
	color:#777;
}

ul.paging .on a {
  font-weight: bold;
  color: #222;
  text-decoration: underline;
}

ul .paging li img{
	width:100%;
}

@media screen and (max-width: 768px) {
ul.paging { 
  width: 100%;
  height: 3.515625vw;
  margin:10.4166vw 0px 19.53125vw 0px;
}

ul.paging li { 
  margin:0px 2.62760vw;
}


ul.paging li a {
  font-size: 3.3854vw;
}
}

.business-gallery .detail-title-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px 0px;
}

.detail-title-box .detail-writer{
  font-size: 26px;
  font-weight: 400;
}

.detail-title-box .detail-writer span{
  font-size: 18px;
  font-weight: 400;
  margin-right: 10px;
}

.detail-writer li{
  font-size: 14px;
  display: inline-block;
  padding: 0px 10px;
}

.detail-writer li:not(:last-child){
  border-right: 1px solid #e9e9e9;
}

.detail-content-box {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}

.detail-content-box .board-content{
  height: auto;
  padding: 30px 0px 50px 0px;
  line-height: 1.5;
  text-underline-offset : 4px;
  font-size: 15px;
}

.detail-content-box .board-file{
  height: auto;
}

@media screen and (max-width: 768px) {
  .detail-title-box{
    display: block;
    margin: 40px 0px;
  }

  .detail-title-box .detail-writer{
    display: flex;
    font-size: 20px;
    justify-content: end;
  }
  
  .detail-title-box .detail-writer span{
    font-size: 14px;
  }

  .detail-title-box .detail-writer li {
    display: none;
  }

  .detail-content-box .board-content {
    padding: 1.302vw;
  }

  .detail-content-box .board-content p{
    font-size: 13px;
    line-height: 2;
  }

  .detail-content-box .board-content p img{
    width: 100%;
  }

  .detail-content-box .board-comment .title{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .name{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .func{
    font-size: 11px;
    font-weight: 600;
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .comment-write {
    padding: 1.302vw;
  }

  .detail-content-box .board-comment .comment-write p{
    font-family: "NanumSquare_acR";
    font-size: 12px;
    line-height: 2;
  }

  .detail-content-box .board-btn .btn-box button{
    text-align: center;
    font-size: 11px;
    justify-content: center;
    align-items: center;
  }

  .detail-content-box .board-btn .btn-box button svg{
    width: 12px;
    height: 12px;
    margin: 0px 3px 0px 3px;
  }
}

.main-partner-system {
  padding: 43px 0;
  text-align: center;
}
@media (min-width: 1020px) {
  .main-partner-system {
    padding: 150px 0 0;
    text-align: center;
  }
}
.main-partner-system .text-area h2 {
  margin-bottom: 10px;
  font-size: 1.5em;
  font-weight: 700;
}
@media (min-width: 1020px) {
  .main-partner-system .text-area h2 {
    font-size: 48px;
  }
}
.main-partner-system .text-area .subment {
  margin-bottom: 26px;
  color: #999999;
}
@media (min-width: 1020px) {
  .main-partner-system .text-area .subment {
    font-size: 18px;
    margin-bottom: 38px;
  }
}
.main-partner-system .text-area p {
  padding: 0 30px 0 0;
  font-size: 0.813em;
  line-height: 1.385em;
  word-break: keep-all;
  margin-bottom: 24px;
}
@media (min-width: 1020px) {
  .main-partner-system .text-area p {
    font-size: 20px;
    margin-bottom: 50px;
  }
}
.main-partner-system .system-list {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: space-between;
  align-items: center;
}
.main-partner-system .system-list li {
  margin-bottom: 34px;
  width: 100%;
}
.main-partner-system .system-list li:last-child {
  margin: 0;
}
@media (min-width: 1020px) {
  .main-partner-system .system-list li {
    margin-right: 20px;
    width: calc(25% - 20px);
  }
}
.main-partner-system .system-list li .system-list-img {
  width: 100%;
  margin-bottom: 24px;
  /* border-radius: 10px; */
  align-items: center;
}
.main-partner-system .system-list li .system-list-img img {
  max-width: 100%;
  max-height: 120px;
}
.main-partner-system .system-list li .system-list-box h3 {
  margin-bottom: 15px;
  font-size: 1em;
  font-weight: 700;
}
@media (min-width: 1020px) {
  .main-partner-system .system-list li .system-list-box h3 {
    font-size: 24px;
  }
}
.main-partner-system .system-list li .system-list-box p {
  width: 90%;
  word-break: keep-all;
  font-size: 0.813em;
  line-height: 1.385em;
}
@media (min-width: 1020px) {
  .main-partner-system .system-list li .system-list-box p {
    width: 100%;
    font-size: 16px;
  }
}


/* 230720 최종 수정 적용건 */
.footer { padding-left:20px; padding-right:20px; }
.footer .inner { padding:0; }
.main-section-consulting .inner .consulting-slider { max-width: 100vh; }
.main-section-consulting .inner .consulting-slider .box p { padding:0 20px; word-break: keep-all; }
@media (min-width: 1020px) {
  .main-section-consulting .inner .consulting-slider .box p {
    font-size: 18px;
  }
}
.container { margin-bottom:0; }
.container.main { padding-bottom: 0; }

[class^='cleaning-section-'],
[class^=value-section-] { margin: 0;  padding:50px 0;}
[class^=cleaning-section-] .text-area { padding-bottom:50px }
.cleaning-section-circle .img-area { margin-bottom:50px }
.cleaning-section-circle .cleaning-circle-list { margin-bottom:0; }

@media (min-width:1020px) {
  .cleaning-section-feature .cleaning-feature-list { padding-bottom:250px; }
  .cleaning-section-feature .cleaning-feature-list ul > li:last-child .content { border-bottom:0; }
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail ul {
  display: grid;
  gap: 0 20px;
  grid-template-columns: repeat(auto-fit, minmax(90px, auto));
  place-items: center;
}
.cleaning-section-feature .cleaning-feature-list .cleaning-feature-item .content .service-detail-list .text-area { padding:10px 0;}


.board-gallery-list ul {
  display: flex;
  flex-wrap: wrap;
  padding-top: 30px;
}
.board-gallery-list ul li {
  flex: 0 0 100%;
  margin-bottom: 30px;
}
.board-gallery-list ul li a {
  display: block;
}
.board-gallery-list ul li a .img {
  height: 210px;
  margin-bottom: 20px;
}
.board-gallery-list ul li a .img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.board-gallery-list ul li a .text {
  max-width: 400px;
}

.board-gallery-list ul li a .text .title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  margin-bottom: 6px;
  font-weight: 700;
}
.board-gallery-list ul li a .text .info {
  display: flex;
  font-size: 13px;
  color: #777;
}
.board-gallery-list ul li a .text .info .date {
  color: inherit;
}
.board-gallery-list ul li a .text .info .date::after {
  content: "";
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 3px;
  background: #777;
  margin: 0 10px;
}
.board-gallery-list ul li a .text .info .view {
  color: inherit;
}

@media (min-width: 768px) and (max-width: 1179px) {
  .board-gallery-list ul {
    margin: -10px;
  }
  .board-gallery-list ul li {
    flex: 0 0 calc(50% - 20px);
    margin: 10px 10px 30px 10px;
  }
}

@media (min-width: 1180px) {
  .board-gallery-list {
    border-top: 1px solid #222;
  }
  .board-gallery-list ul {
    margin: -10px;
    padding-top: 65px;
  }
  .board-gallery-list ul li {
    flex: 0 0 calc(33.33% - 20px);
    margin: 10px 10px 55px 10px;
  }
  .board-gallery-list ul li a .text .title {
    font-size: 18px;
  }
  .board-gallery-list ul li a .text .info {
    font-size: 16px;
  }

}

.board .board-paginate {
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.board .board-paginate a, .board-paginate div, .board .board-paginate span {
  display: inline-flex;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #777777;
  justify-content: center;
}
.board .board-paginate .prev {
  text-indent: -9999999px;
  overflow: hidden;
  background: url(../images/common/page_prev.png) center no-repeat;
}
.board .board-paginate .next {
  text-indent: -9999999px;
  overflow: hidden;
  background: url(../images/common/page_next.png) center no-repeat;
}
.board .board-paginate .current div {
  color: #222;
  font-weight: 900;
  text-decoration: underline;
}
.board .board-paginate ul {
  display: inline-flex;
  margin: 0 30px;
}

.quill {
  margin-top: 20px;
  margin-bottom: 50px;
  height: 500px;
  font-size: 35px;
}
.ql-container{
  font-size: 16px;
}
.ql-container .ql-editor {
  box-sizing: border-box;
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-container p {
  box-sizing: border-box;
  font-size: 16px;
  color: #565759;
  margin: 0px;
  position: relative;
}

.ql-toolbar.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 10px 10px 0 0 ;
}

.ql-container.ql-snow {
  border: 1px solid #eee !important;
  border-radius: 0 0 10px 10px ;
}